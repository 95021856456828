import { onDomChanges } from '../../components/dynamic/observer';
import { validatorCleanErrors, validatorValidateForm } from '../../components/forms/validation';
import axios from 'axios';

class ImagesField {
  constructor(element) {
    element.dataset.initialized = 'true';
    this.element = element;
    this.input = this.element.querySelector('[data-images-field-input]');
    this.list = this.element.querySelector('[data-images-field-list]');
    this.accept = this.input.getAttribute('accept').split(',').map((item) => item.trim());
    this.maxSize = parseInt(this.input.dataset.imagesFieldMaxSize, 10);

    this.form = this.element.closest('form');

    this.currentImages = this.form.querySelectorAll('[data-current-image]');

    this.errors = {
      size: 'Размер файла не должен превышать 5 Мб',
      format: 'Допустимые форматы: jpg, jpeg, png',
      count: 'Можно загрузить не более 5 файлов',
    };

    this.dt = new DataTransfer();

    this.bind();
  }

  bind() {
    this.bindInput();
    this.bindDelete();
  }

  bindInput() {
    const instance = this;
    this.input.addEventListener('change', () => {
      validatorCleanErrors(instance.form, instance.form.dataset.formName);
      instance.updateFromInput();
    });

    this.input.addEventListener('clear', () => instance.clear());
  }

  bindDelete() {
    const instance = this;
    this.currentImages.forEach((item) => {
      const deleter = item.querySelector('[data-delete]');
      if (deleter) {
        deleter.addEventListener('click', (e) => {
          e.preventDefault();
          instance.deleteImageModel(item);
        });
      }
    });
  }

  deleteImageModel(item) {
    const data = {
      class: item.dataset.class,
      id: item.dataset.id,
    };
    axios.post(item.dataset.deleteUrl, data)
      .then((response) => {
        if (response.data.state === 'success') {
          item.remove();
        } else {
          console.log(response)
        }
      })
     .catch((error) => {
        console.log(error);
      });
  }

  updateFromInput() {
    const instance = this;
    const dt = this.filterInput();
    Array.from(dt.files).forEach((file) => {instance.dt.items.add(file);});

    this.input.files = this.dt.files;

    this.updateList();
  }


  updateList() {
    const instance = this;

    const { files } = this.input;
    this.list.querySelectorAll('[data-added-image]').forEach((item) => item.remove());
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        instance.addImage(file, i);
      }
    }

    refreshFsLightbox();
  }

  filterInput() {
    const dt = new DataTransfer();
    const { files } = this.input;

    let errors = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let hasError = false;
      if (file) {
        if (file.size > this.maxSize) {
          hasError = true;
          if (!errors.includes('size')) {
            errors.push('size');
          }
        }

        if (!this.accept.includes(file.type)) {
          hasError = true;
          if (!errors.includes('format')) {
            errors.push('format');
          }
        }

        if (i > 4) {
          hasError = true;
          if (!errors.includes('count')) {
            errors.push('count');
          }
        }

        if (!hasError) {
          dt.items.add(file);
        }
      }
    }

    if (errors.length) {
      errors = errors.map((item) => this.errors[item]);
      validatorValidateForm(this.form, this.form.dataset.formName, { files: errors });
    }

    return dt;
  }

  filterImages() {
    const dt = new DataTransfer();

    Array.from(this.dt.files).forEach((file) => dt.items.add(file));

    const { files } = this.input;

    let errors = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let hasError = false;
      if (file) {
        if (file.size > this.maxSize) {
          hasError = true;
          if (!errors.includes('size')) {
            errors.push('size');
          }
        }

        if (!this.accept.includes(file.type)) {
          hasError = true;
          if (!errors.includes('format')) {
            errors.push('format');
          }
        }

        if (i > 4) {
          hasError = true;
          if (!errors.includes('count')) {
            errors.push('count');
          }
        }

        if (!hasError) {
          dt.items.add(file);
        }
      }
    }

    this.dt = dt;
    this.input.files = this.dt.files;

    if (errors.length) {
      errors = errors.map((item) => this.errors[item]);
      validatorValidateForm(this.form, this.form.dataset.formName, { files: errors });
    }
  }

  clear() {
    this.list.innerHTML = '';
    this.input.value = '';
  }

  removeFile(index) {
    const dt = new DataTransfer();
    const { files } = this.input;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file && index !== i) {
        dt.items.add(file);
      }
    }

    this.dt = dt;
    this.input.files = dt.files;
    // this.list.innerHTML = '';
    this.updateList();
  }

  addImage(file, index) {
    const instance = this;
    const url = URL.createObjectURL(file);

    const a = document.createElement('a');

    const img = document.createElement('img');
    img.classList.add('images-field__image_img');
    img.src = url;

    a.dataset.fslightbox = 'publication';

    img.classList.add('images-field__image');

    a.href = url;
    a.classList.add('images-field__link');
    a.append(img);

    const closer = document.createElement('a');
    closer.href = 'javascript:void(0)';
    closer.classList.add('images-field__closer');

    const indexElem = document.createElement('div');
    indexElem.innerHTML = index;

    const li = document.createElement('li');
    li.classList.add('images-field__item');
    li.dataset.addedImage = 'true';
    li.appendChild(a);
    li.appendChild(closer);
    li.appendChild(indexElem);

    this.list.appendChild(li);

    closer.addEventListener('click', (e) => {
      e.preventDefault();
      li.remove();
      instance.removeFile(index);
    });
  }
}
function init() {
  document.querySelectorAll('[data-images-field]:not([data-initialized="true"])')
    .forEach((field) => new ImagesField(field));
}

onDomChanges(init);