require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('../fonts/nunitosans/nunitosans.css');

require('./common/true-vh');
// require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone.js');
require('./field/custom-select');
require('./field/password-input');

// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');

require('./sidebar/sidebar');

require('./slots/index');
require('./dashboard/index');
require('./search');
require('./filters/index');
require('./vk-id-getter');
require('./event-replacer/index');
require('./pay');
require('./common/copy-on-click');
require('./vk/group-matcher');

require('./publications/publicationForm');
require('./field/images-field');

require('fslightbox');
require('./FsLightbox/index');