import axios from 'axios';
import Modal from '../../components/modal/modal';
import { validatorValidateForm, validatorCleanErrors } from '../../components/forms/validation';

class PublicationForm {
  constructor(detail) {
    this.groupSlotId = detail.groupSlotId;
    this.mainSlotId = detail.mainSlotId;

    this.modal = null;

    this.init();
  }

  async init() {
    await this.initModal();

    if (this.modal) {
      this.form = this.modal.content.querySelector('[data-publication-form]');
      this.formName = this.form.dataset.formName;
      this.toSlotButtons = this.modal.content.querySelectorAll('[data-to-slot]');
      this.ordCheckbox = this.form.querySelector(`#${this.formName}_marking`);
      this.bind();
    }
  }

  bind() {
    this.bindSubmit();
    this.bindOrdCheckbox();

    if (this.toSlotButtons.length) {
      this.bindToSlotButtons();
    }
  }

  bindOrdCheckbox() {
    if (this.ordCheckbox) {
      const instance = this;

      instance.handleOrd(this.ordCheckbox.checked);
      this.ordCheckbox.addEventListener('change', (e) => instance.handleOrd(e.target.checked));
    }
  }

  handleOrd(show) {
    const elements = this.modal.content.querySelectorAll('[data-for-ord]');
    elements.forEach((element) => {
      element.style.display = show ? 'block' : 'none';
    });
  }

  bindSubmit() {
    const instance = this;
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.submitForm();
    });
  }

  bindToSlotButtons() {
    const instance = this;
    this.toSlotButtons.forEach((button) => {
      const slotId = button.dataset.toSlot;
      button.addEventListener('click', (e) => {
        e.preventDefault();
        instance.modal.close();
        document.dispatchEvent(new CustomEvent('slot:edit', { detail: { slotId } }));
      });
    });
  }

  async submitForm() {
    this.cleanErrors();
    const formData = new FormData(this.form);
    await this.request(this.form.getAttribute('action'), formData);
  }

  async request(url, formData) {
    const instance = this;
    await axios.post(url, formData)
      .then((response) => {
        const { data } = response;
        if (data.success || data.state === 'success') {
          instance.handleSuccess(data);
        } else {
          instance.handleErrors(data.errors);
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  handleSuccess(data) {
    this.modal.close();
  }

  handleErrors(errors) {
    validatorValidateForm(this.form, this.formName, errors[this.formName]);
  }

  cleanErrors() {
    validatorCleanErrors(this.form, this.formName);
  }

  async initModal() {
    const instance = this;
    const data = {
      group_slot_id: instance.groupSlotId,
      main_slot_id: instance.mainSlotId,
    };
    await axios.post('/vk/publication/modal', data)
      .then((result) => {
        const element = document.createElement('div');
        element.innerHTML = result.data;

        instance.modal = new Modal(element, {
          preloader: false,
          closerText: 'x',
          useAjaxForm: true,
          closeOnClickBg: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

document.addEventListener('openPublication', (e) => {
  new PublicationForm(e.detail);
});